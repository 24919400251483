import React, { useState } from "react";
import Navigation from "./navigation";

const MobileNavigation = ({ isVisible, onToggleMenu, menuState }) => {
  const handleClick = () => {
    onToggleMenu(!menuState);
  };

  return (
    <div className="mobile-nav">
      <div
        onClick={handleClick}
        className="overlay fixed h-full w-full top-0 left-0 z-40"
      >
        <div className="mobile-wrap relative flex justify-center flex-col w-full h-screen">
          <Navigation />
        </div>
      </div>
    </div>
  );
};

export default MobileNavigation;
