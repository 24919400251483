import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout/layout";
import Hero from "../components/hero/hero";
import TextSectionImg from "../components/textSection/textSectionImg";

const Library = () => {
  const sectionBodyObject = {
    sectionOne: `
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Duis aute irure dolor in
        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
        culpa qui officia deserunt mollit anim id est laborum.
        `,
  };

  return (
    <Layout title={"Component Library"}>
      <Hero
        header="Component Library"
        description="This page displays each variation and available option of the current components"
      >
        <StaticImage
          className="mobile-hero"
          alt=""
          src={`../images/hero-img-mob.jpg`}
        />
        <StaticImage
          className="desktop-hero"
          alt=""
          src={`../images/hero-img-one.jpg`}
        />
      </Hero>
      <TextSectionImg
        heading="Text"
        headingAccent="Section"
        body={sectionBodyObject.sectionOne}
        button={{ text: "Read More", link: "http://trost.dev" }}
      >
        <StaticImage
          class="shadow-lg"
          alt=""
          src={`../images/hero-img-mob.jpg`}
        />
      </TextSectionImg>
    </Layout>
  );
};

export default Library;
