import React, { useEffect, useState } from "react";

const LargeSectionComponent = ({ children, ...props }) => {
  const [bgColor, setBgColor] = useState("bg-primary");
  const [textColor, setTextColor] = useState("text-white");

  useEffect(() => {
    if (props.color == "white") {
      setBgColor((bgColor) => (bgColor = "bg-white"));
      setTextColor((textColor) => (textColor = "text-primary"));
    }
  }, []);

  return (
    <div className={`p-10 ${bgColor}`}>
      <div
        className={`min-h-screen flex flex-col justify-center max-w-5xl m-auto ${textColor}`}
      >
        {children}
      </div>
    </div>
  );
};

export default LargeSectionComponent;
