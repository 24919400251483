import React from "react";

import Navigation from "../navigation/navigation";

const Footer = () => {
  return (
    <div className="absolute bottom-0 h-40 w-full bg-primary flex items-center flex-col justify-center border-t border-accent">
      <footer className="flex justify-center md:justify-between text-center md:text-left my-6 md:my-0 items-center px-10 w-full">
        <Navigation />
        <div className="footer-center">
          <h3 className="color-primary"> </h3>
        </div>
      </footer>
      <div className="copyright w-full text-center text-xs">
        &copy; 2021, Brand
      </div>
    </div>
  );
};

export default Footer;
