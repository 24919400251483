import React, { useEffect } from "react";

import Navigation from "../navigation/navigation";
import menuSVG from "../../images/menu.svg";

const Header = ({ onToggleMenu, menuState }) => {
  const handleClick = () => {
    onToggleMenu(!menuState);
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY < 21) {
        document.getElementById("header").style.background = "transparent";
        document.getElementById("brand").style.color = "white";
      }
      if (window.scrollY > 20) {
        document.getElementById("header").style.background = "#264653";
        document.getElementById("brand").style.color = "#E9C46A";
      }
    });
  }, []);

  return (
    <div id="navbar" className="fixed top-0 left-0 w-full z-50">
      <header
        id="header"
        className="flex justify-between h-16 px-4 md:px-10 bg-transparent items-center"
      >
        <a href="/">
          <h3 id="brand" className="text-white">
            Brand
          </h3>
        </a>
        <div onClick={handleClick} className="mobile-toggle sm:hidden">
          <img width={40} src={menuSVG} />
        </div>
        <div className="hidden sm:block">
          <Navigation />
        </div>
      </header>
    </div>
  );
};

export default Header;
