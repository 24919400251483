import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout/layout";
import Hero from "../components/hero/hero";

import "@fontsource/playfair-display";

// markup
const AboutPage = () => {
  return (
    <Layout title={"About"}>
      <Hero header="Hero Component" description="This is the Hero Component">
        <StaticImage
          className="mobile-hero"
          alt=""
          placeholder="blurred"
          layout={"fullWidth"}
          src={`../images/hero-img-mob.jpg`}
        />
        <StaticImage
          className="desktop-hero"
          alt=""
          placeholder="blurred"
          layout={"fullWidth"}
          src={`../images/hero-img-two.jpg`}
        />
      </Hero>
    </Layout>
  );
};

export default AboutPage;
