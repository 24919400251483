import React from "react";

const Navigation = () => {
  return (
    <div className="navigation flex space-between text-white">
      <a href="/about" className="md:mx-6">
        About
      </a>
      <a href="/services" className="md:mx-6">
        Services
      </a>
      <a href="/contact" className="btn md:mx-6">
        Contact
      </a>
      <a href="http://conradtrost.com" className="btn md:mx-6">
        Demos
      </a>
    </div>
  );
};

export default Navigation;
