import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "@fontsource/playfair-display";

import Layout from "../components/layout/layout";
import Hero from "../components/hero/hero";

// markup
const ServicesPage = () => {
  return (
    <Layout title={"Services"}>
      <Hero header="Services Page" description="This is the services page.">
        <StaticImage
          className="mobile-hero"
          alt=""
          placeholder="blurred"
          layout={"fullWidth"}
          src={`../images/hero-img-mob.jpg`}
        />
        <StaticImage
          className="desktop-hero"
          alt=""
          placeholder="blurred"
          layout={"fullWidth"}
          src={`../images/hero-img-three.jpg`}
        />
      </Hero>
    </Layout>
  );
};

export default ServicesPage;
