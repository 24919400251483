import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./textSection.module.css";

const TextSectionImg = ({ children, ...props }) => {
  const [bgColor, setBgColor] = useState("bg-primary");
  const [textColor, setTextColor] = useState("text-white");

  useEffect(() => {
    if (props.color == "white") {
      setBgColor((bgColor) => (bgColor = "bg-white"));
      setTextColor((textColor) => (textColor = "text-primary"));
    }
  }, []);

  return (
    <>
      <div className="flex-col-reverse flex md:grid grid-cols-12">
        <div className="pr-6 flex flex-col justify-center col-span-8">
          <h2>
            {props.heading}{" "}
            <span className="accent">{props.headingAccent}</span>
          </h2>
          <h3>{props.subheading}</h3>
          <p>{props.body}</p>
          {props.button ? (
            <a className={`btn ${styles.btn}`} href={props.button.link}>
              {props.button.text}
            </a>
          ) : (
            ""
          )}
        </div>
        <div className="relative col-span-4">{children}</div>
      </div>
    </>
  );
};

export default TextSectionImg;
