import React from "react";

import * as styles from "./hero.module.css";

const Hero = ({ children, header, description }) => {
  return (
    <div className={`${styles.hero} w-full relative`}>
      {children}
      <div className={`${styles.heroInner} absolute text-center`}>
        <h1>{header}</h1>
        <h3>{description}</h3>
      </div>
    </div>
  );
};

export default Hero;
