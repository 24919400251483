import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout/layout";
import Hero from "../components/hero/hero";
import TextSectionImg from "../components/textSection/textSectionImg";

import "@fontsource/playfair-display";
import LargeSectionComponent from "../components/layout/largeSection";
import SmallSectionComponent from "../components/layout/smallSection";

// markup
const IndexPage = () => {
  const sectionBodyObject = {
    sectionOne: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    aliquip ex ea commodo consequat. Duis aute irure dolor in
    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    culpa qui officia deserunt mollit anim id est laborum.
    `,
    sectionTwo: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    aliquip ex ea commodo consequat. Duis aute irure dolor in
    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    culpa qui officia deserunt mollit anim id est laborum.
    `,
    sectionThree: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    aliquip ex ea commodo consequat. Duis aute irure dolor in
    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    culpa qui officia deserunt mollit anim id est laborum.
    `,
  };

  return (
    <Layout title={"Home"}>
      <Hero header="Company Name" description="Company Description">
        <StaticImage
          className="mobile-hero"
          alt=""
          src={`../images/hero-img-mob.jpg`}
        />
        <StaticImage
          className="desktop-hero"
          alt=""
          src={`../images/hero-img-one.jpg`}
        />
      </Hero>
      <SmallSectionComponent color="white">
        <div>
          <h2>
            Small Text
            <span className="accent"> Section</span>
          </h2>
          <h3>White Background</h3>
          <p>{sectionBodyObject.sectionOne}</p>
        </div>
      </SmallSectionComponent>
      <LargeSectionComponent>
        <div>
          <h2>
            Large Text
            <span className="accent"> Section</span>
          </h2>
          <h3>Primary Background</h3>
          <p>{sectionBodyObject.sectionOne}</p>
        </div>
        <div className="mt-2">
          <h3>
            Large Second Text
            <span className="accent"> Section</span>
          </h3>
          <h4>Primary Background</h4>
          <p>{sectionBodyObject.sectionOne}</p>
        </div>
      </LargeSectionComponent>
      <LargeSectionComponent color="white">
        <div>
          <h2>
            Text
            <span className="accent"> Section</span>
          </h2>
          <h3>White Background</h3>
          <p>{sectionBodyObject.sectionOne}</p>
        </div>
      </LargeSectionComponent>
      <LargeSectionComponent>
        <TextSectionImg
          heading="Text Section"
          headingAccent="with Image"
          body={sectionBodyObject.sectionOne}
          button={{ text: "Read More", link: "http://trost.dev" }}
        >
          <StaticImage
            class="shadow-lg"
            alt=""
            src={`../images/hero-img-mob.jpg`}
          />
        </TextSectionImg>
      </LargeSectionComponent>
    </Layout>
  );
};

export default IndexPage;
