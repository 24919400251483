import React, { useState } from "react";

import Header from "../header/header";
import Footer from "../footer/footer";
import MobileNavigation from "../navigation/mobileNavigation";
import { Helmet } from "react-helmet";

export default function Layout({ children, title }) {
  const [menuState, setMenuState] = useState(false);

  return (
    <div>
      <Helmet>
        <title>{title} | Demo</title>
      </Helmet>
      <div
        className={`bg-primary relative overflow-hidden ${
          menuState ? "active-mobile-nav" : ""
        }`}
      >
        <MobileNavigation
          menuState={menuState}
          onToggleMenu={setMenuState}
          isVisible={menuState}
        />
        <Header menuState={menuState} onToggleMenu={setMenuState} />
        <main className="min-h-screen pb-40">{children}</main>
        <Footer />
      </div>
    </div>
  );
}
